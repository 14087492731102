import { type ArticlePreviewType } from "~/types";
import { type FC } from "react";
import GridWithPreviewItem from "./PreviewspecificArticle/GridWithPreviewItem";
import GridWithAllPublishedItems from "./PreviewspecificArticle/GridWithAllPublishedItems";

type PreviewspecificArticletProps = {
  article: ArticlePreviewType[];
};

const PreviewspecificArticle: FC<PreviewspecificArticletProps> = ({
  article,
}) => {
  const todayDate = new Date().toISOString().split("T")[0];
  const currentDay = new Date().getDate();
  const previewFromDay = 15;
  const previewToDay = 31;

  return (
    <div className="grid max-w-box grid-cols-1 gap-8 pb-8 sm:grid-cols-2 lg:grid-cols-4 print:grid-cols-2">
      <>
        {article[0].post_status == "future" && currentDay >= previewFromDay ? (
          <>
            {article.slice(0, 4).map((article, index) => (
              <div key={article.post_name} className={`border-box flex`}>
                <article className="flex h-full w-full grow flex-col gap-5">
                  <div className=" mr-8 flex h-full grow flex-col justify-between">
                    <GridWithPreviewItem
                      article={article}
                      todayDate={todayDate}
                      currentDay={currentDay}
                      index={index}
                      previewFromDay={previewFromDay}
                      previewToDay={previewToDay}
                    />
                  </div>
                </article>
              </div>
            ))}
          </>
        ) : (
          <>
            {article
              .filter(
                (article, index) =>
                  !(index === 0 && article.post_status == "future")
              )
              .slice(0, 4)
              .map((article, index) => (
                <div key={article.post_name} className={`border-box flex`}>
                  <article className="flex h-full w-full grow flex-col gap-5">
                    <div className=" mr-8 flex h-full grow flex-col justify-between">
                      <GridWithAllPublishedItems
                        article={article}
                        index={index}
                      />
                    </div>
                  </article>
                </div>
              ))}
          </>
        )}
      </>
    </div>
  );
};

export default PreviewspecificArticle;
