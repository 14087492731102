import { Link } from "react-alice-carousel";
import { type ISSUES } from "~/types";
import Image from "next/image";
import Badge from "./Badge";
import { returnUrlTypeCategory } from "~/utils/utils";
import { Fragment } from "react";

export type postNumber = {
  name: string;
  slug: string;
  taxonomy?: string;
};

const PreviewCarouselLoop = ({ article }) => {
  const postExcerpt = article.post_excerpt;
  const numbers: postNumber[] = article?.meta?.number
    ? Object.values(article?.meta?.number)
    : null;

  return (
    <article className="flex grow flex-col">
      <figure>
        <Link
          href={`/a/${article.post_name}`}
          className="flex w-full rounded-[50px] bg-lightLightGray transition-all"
        >
          {article.thumbnail != null && (
            <Image
              src={article.thumbnail}
              width={375}
              height={300}
              alt={article?.alt || "immagine di copertina"}
              className="!aspect-5/3 h-full w-full rounded-[50px] object-cover mix-blend-multiply hover:opacity-80"
            />
          )}
        </Link>
      </figure>
      <div className="entry-header flex flex-col text-center">
        <header>
          <p className="pb-4 pt-6 md:pb-6 md:pt-12 print:my-2">
            {numbers &&
              numbers?.map((number) => (
                <Fragment key={number.slug}>
                  <Link
                    className="entry-title text-sm font-light uppercase transition-all hover:text-orange print:my-2"
                    href={returnUrlTypeCategory(
                      number.slug,
                      number.taxonomy as ISSUES
                    )}
                  >
                    BS {number.slug.replace("&amp;", " & ")}
                  </Link>
                </Fragment>
              ))}
            &nbsp; /&nbsp;
            <Link
              className="entry-title text-sm font-bold uppercase transition-all hover:text-orange print:my-2"
              href={`/a/${article.post_name}`}
              dangerouslySetInnerHTML={{ __html: article.post_title }}
            ></Link>
          </p>
          <Badge meta={article?.meta} style="border border-white" />
        </header>
        <div className="entry-summary text-sm print:my-2">
          <span>{article?.meta?.sottotitolo}</span>
        </div>
      </div>
    </article>
  );
};

export default PreviewCarouselLoop;
