import { type TaxonomyDBReturnType } from "~/lib/db/types";
import { ISSUES_TYPE, type MetaObjectParsed, type ISSUES } from "~/types";

type testCategory = {
  catHeading?: {
    name: string;
    slug: string;
    parentName: string;
    parentSlug: string;
    taxonomy: string;
  };
  main?: {
    name: string;
    slug: string;
    taxonomy: string;
  }[];
  sub?: {
    name: string;
    slug: string;
    parentName: string;
    parentSlug: string;
    taxonomy: string;
  }[];
};

export const getQueryParamAsArray = <T>(
  queryParam: unknown | unknown[],
  defaultValue?: T
) => {
  if (!queryParam && !defaultValue) return;
  return Array.isArray(queryParam)
    ? (queryParam as T[])
    : [(queryParam as T) || defaultValue];
};

export const returnUrlTypeCategory = (
  category: string,
  typeCategory: ISSUES
) => {
  if (typeCategory == ISSUES_TYPE.multimedia) {
    return `/m/${category}`;
  }

  if (typeCategory === ISSUES_TYPE.numbers) {
    return `/n/${category}`;
  }

  if (typeCategory === ISSUES_TYPE.speciali) {
    return `/s/${category}`;
  }

  return `/c/${category}`;
};

/*Questa funzione serve per rimuovere dalla categorie gli speciali, per quelle pagine che non li devono mostrare*/
export const filterCategorySpecial = (
  meta: MetaObjectParsed,
  categoria: string
) => {
  let categories = null;
  if (meta) {
    if (categoria == "category") {
      categories = meta.number ? Object.values(meta.number) : null;
    } else {
      if (categoria == "speciale") {
        categories = meta?.cat_heading
          ? [meta?.cat_heading]
          : Object.values(meta?.categories).filter(
              (cat) => cat.taxonomy !== ISSUES_TYPE.speciali
            );
      } else {
        categories = meta?.cat_heading
          ? [meta?.cat_heading]
          : Object.values(meta?.categories);
      }
    }
  }
  return categories;
};

export const testSplitCategory = (
  taxonomy: TaxonomyDBReturnType[],
  catHeading: TaxonomyDBReturnType[],
  typeTaxonomy: string
): testCategory => {
  const categories: testCategory = {
    main: [],
    sub: [],
  };
  const mainCategory = taxonomy.filter(
    (item) => item.taxonomy != ISSUES_TYPE.numbers && item.parent_slug == null
  );
  const subCategory = taxonomy.filter(
    (item) => item.taxonomy != ISSUES_TYPE.numbers && item.parent_slug != null
  );
  catHeading?.map((el) => {
    if (el.taxonomy == typeTaxonomy) {
      categories.catHeading = {
        name: el.name,
        slug: el.slug,
        parentName: el.parent_name,
        parentSlug: el.parent_slug,
        taxonomy: el.taxonomy,
      };
    }
  });

  mainCategory?.map((el) => {
    if (el.taxonomy == typeTaxonomy) {
      categories.main.push({
        name: el.name,
        slug: el.slug,
        taxonomy: el.taxonomy,
      });
    }
  });

  subCategory?.map((el) => {
    if (el.taxonomy == typeTaxonomy) {
      categories.sub.push({
        name: el.name,
        slug: el.slug,
        parentName: el.parent_name,
        parentSlug: el.parent_slug,
        taxonomy: el.taxonomy,
      });
    }
  });
  return categories;
};

export const imageShowSpecialFunction = (
  category: string,
  meta: MetaObjectParsed
) => {
  const categoryExcluded = [
    "Recensioni",
    "Invito alla lettura",
    "Scritti di Daisaku Ikeda",
  ];
  let show = true;
  if (category != "speciale") return true;
  if (
    meta?.cat_heading != undefined &&
    categoryExcluded.includes(meta?.cat_heading?.name)
  )
    return false;
  if (meta?.categories != undefined) {
    const categories = Object.values(meta?.categories);

    categories.filter((category) => {
      if (categoryExcluded.includes(category?.name) && show == true)
        show = false;
    });
  }
  return show;
};
