import React, { type FC } from "react";
import { RESPONSIVE_CAROUSEL } from "~/utils/constants";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import NextButton from "./NextButton";
import PrevButton from "./PrevButton";
import { type InfoSpeciali } from "~/lib/db/speciali";
import PreviewSpecialiCarouselLoop from "./ArticlePreview/PreviewSpecialiCarouselLoop";

type CarouselLoopProps = {
  carouselLoop: InfoSpeciali[];
};

const CarouselLoopSpeciale: FC<CarouselLoopProps> = ({ carouselLoop }) => {
  return (
    <AliceCarousel
      mouseTracking={true}
      responsive={RESPONSIVE_CAROUSEL}
      autoPlay={true}
      autoPlayInterval={4000}
      disableDotsControls={true}
      disableButtonsControls={false}
      renderPrevButton={() => {
        return <PrevButton color={"black"} />;
      }}
      renderNextButton={() => {
        return <NextButton color={"black"} />;
      }}
    >
      {carouselLoop.map((article) => (
        <div
          key={`${article.name}`}
          className="articleWrap carousel-cell h-100 aspect-5/3 flex w-full flex-col px-2 md:px-2"
        >
          <PreviewSpecialiCarouselLoop article={article} />
        </div>
      ))}
    </AliceCarousel>
  );
};

export default CarouselLoopSpeciale;
