export enum FIELDS {
  CONTENT = "CONTENT",
  AUTORE = "AUTORE",
  AUDIO = "AUDIO",
  VIDEO = "VIDEO",
}

export enum SEARCH_TYPE {
  AND = "AND",
  OR = "OR",
  EXACT = "EXACT",
}

export const SELECTABLE_TYPES = [
  "Almeno una di queste parole",
  "Tutte queste parole",
  "Questa esatta parola o frase",
];

export const mapSelectToType: { [x: string]: SEARCH_TYPE } = {
  [SELECTABLE_TYPES[0]]: SEARCH_TYPE.OR,
  [SELECTABLE_TYPES[1]]: SEARCH_TYPE.AND,
  [SELECTABLE_TYPES[2]]: SEARCH_TYPE.EXACT,
};

export const mapSearchType: Record<SEARCH_TYPE, string> = {
  [SEARCH_TYPE.OR]: "Almeno una di queste parole",
  [SEARCH_TYPE.AND]: "Tutte queste parole",
  [SEARCH_TYPE.EXACT]: "Questa esatta parola o frase",
};

export const DATES: string[] = new Array(25)
  .fill(0)
  .map((_, i) => (i + 1257).toString());

export enum ORDER_TYPE {
  BYDATE = "byDate",
  BYRELEVANCE = "byRelevance",
}

export const DEFAULT_PAGE_SIZE = 20;

export const RESPONSIVE_CAROUSEL = {
  0: { items: 1.1 },
  768: { items: 1.7 },
  1024: { items: 3.7 },
  1300: { items: 4.7 },
};

export const LOGIN_SITES_TYPE = ["abb", "bs", "nr"];
