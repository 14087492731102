import { type FC } from "react";
import arrowRight from "@public/icon/arrow-right.svg";
import arrowRightWhite from "@public/icon/arrow-right-white.svg";
import arrowRightLongLight from "@public/icon/icon-long-arrow-white.svg";
import arrowRightLong from "@public/icon/icon-long-arrow.svg";
import Link from "next/link";
import Image from "next/image";

type ArrowRightProps = {
  href: string;
  color: string;
  message: string;
  divStyles?: string;
};

const ArrowRightLong: FC<ArrowRightProps> = ({
  href,
  color,
  message,
  divStyles = "",
}) => {
  return (
    <Link
      href={href}
      className={`flex items-center gap-x-5 text-sm ${divStyles} font-bold transition-all hover:opacity-75 text-${color}`}
    >
      <Image
        src={color == "black" ? arrowRightLong : arrowRightLongLight}
        width={65}
        height={18}
        alt={"freccia Lunga"}
        className="max-sm:w-[55px] max-[269px]:h-auto max-[269px]:w-[40px]"
      />
      <p className="">{message}</p>
    </Link>
  );
};

const ArrowRight: FC<ArrowRightProps> = ({
  href,
  color,
  message,
  divStyles,
}) => {
  return (
    <div className={divStyles}>
      <Link
        className={`flex items-center gap-x-2 text-[13px] font-medium uppercase tracking-[2px] transition-all hover:opacity-75 sm:gap-x-3 text-${color}`}
        href={href}
      >
        {`${message} `}
        <div
          className={`${
            color != "black" && "border-white"
          } flex rounded-full border p-1 border-${color}`}
        >
          <Image
            className="h-[12px] w-[12px]"
            src={color == "black" ? arrowRight : arrowRightWhite}
            alt={"arrowRightWhite"}
          ></Image>
        </div>
      </Link>
    </div>
  );
};

export { ArrowRight, ArrowRightLong };
