import Link from "next/link";
import React, { Fragment, type FC } from "react";
import { type ArticlePreviewType } from "~/types";
import { formatISODateString } from "~/utils/dateFormat";
import { type postNumber } from "../PreviewCarouselLoop";

type GridWithPreviewItemProps = {
  article: ArticlePreviewType;
  todayDate: string;
  currentDay: number;
  previewFromDay: number;
  previewToDay: number;
  index: number;
};

const GridWithPreviewItem: FC<GridWithPreviewItemProps> = ({
  article,
  todayDate,
  currentDay,
  previewFromDay,
  previewToDay,
  index,
}) => {
  const postDate = formatISODateString(article.post_date).split(" ");
  const numbers: postNumber[] = article?.meta?.number
    ? Object.values(article?.meta?.number)
    : null;

  return (
    <>
      <div className="flex flex-col text-white">
        {numbers &&
          numbers?.map((number) => (
            <Fragment key={number.name}>
              {index == 0 &&
              currentDay >= previewFromDay &&
              article.post_date > todayDate ? (
                <span className="entry-title text-sm font-medium uppercase print:my-2">
                  {`${postDate[1]}`} BS {number.name.replace("&amp;", " & ")}
                </span>
              ) : (
                <Link
                  href={`/n/${number.slug}`}
                  className="entry-title text-sm font-medium uppercase print:my-2"
                >
                  {`${postDate[1]}`} BS {number.name.replace("&amp;", " & ")}
                </Link>
              )}
            </Fragment>
          ))}
        {index == 0 &&
        currentDay >= previewFromDay &&
        article.post_date > todayDate ? (
          <h3 className="mt-4 text-xl font-bold uppercase sm:text-[22px]">
            Anteprima
            <br />
            del prossimo mese
          </h3>
        ) : (
          <h3 className="mt-4 text-base leading-snug max-sm:text-2xl md:mt-6">
            {article.post_title}
          </h3>
        )}
      </div>
      <div className="flex pb-10 pt-4 md:py-12">
        <Link
          className={`${
            index == 0 &&
            currentDay >= previewFromDay &&
            article.post_date > todayDate
              ? "bg-orange text-white transition-all"
              : index == 1
              ? "bg-[#009BA2] text-white hover:bg-white hover:text-black"
              : "bg-white text-black hover:bg-[#009BA2] hover:text-white"
          } px-12 py-4 text-[13px] font-medium uppercase tracking-[3px] transition-all hover:bg-[#009BA2] md:px-16 md:py-5`}
          href={`/a/${article.post_name}`}
        >
          scopri
        </Link>
      </div>
    </>
  );
};

export default GridWithPreviewItem;
