import Link from "next/link";
import { formatISODateString } from "~/utils/dateFormat";
import React, { Fragment, type FC } from "react";
import { type ArticlePreviewType } from "~/types";
import { type postNumber } from "../PreviewCarouselLoop";

type GridWithAllPublishedItemsProps = {
  article: ArticlePreviewType;
  index: number;
};

const GridWithAllPublishedItems: FC<GridWithAllPublishedItemsProps> = ({
  article,
  index,
}) => {
  const postDate = formatISODateString(article.post_date).split(" ");
  const numbers: postNumber[] = article?.meta?.number
    ? Object.values(article?.meta?.number)
    : null;

  return (
    <>
      <div className="flex flex-col  text-white">
        {numbers &&
          numbers?.map((number) => (
            <Fragment key={number.name}>
              <Link
                href={`/n/${number.slug}`}
                className="entry-title text-sm font-medium uppercase print:my-2"
              >
                {`${postDate[1]}`} BS {number.name.replace("&amp;", " & ")}
              </Link>
            </Fragment>
          ))}
        <h3 className="mt-6 text-base leading-snug max-sm:text-2xl">
          {article.post_title}
        </h3>
      </div>
      <div className="flex  py-8 md:py-12">
        <Link
          className={`${
            index == 0
              ? "bg-[#009BA2] text-white hover:bg-white hover:text-black"
              : "bg-white text-black hover:bg-green hover:text-white"
          }  px-12 py-4 text-[13px] font-medium uppercase tracking-[3px]  transition-all   md:px-16 md:py-5`}
          href={`/a/${article.post_name}`}
        >
          scopri
        </Link>
      </div>
    </>
  );
};

export default GridWithAllPublishedItems;
