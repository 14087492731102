import React, { type FC } from "react";
import { type ArticlePreviewType } from "~/types";
import Link from "next/link";
import CategorieList from "../CategoriesList";
import Badge from "../ArticlePreview/Badge";
import { ArrowRightLong } from "../ArrowComponents";

type ContenutiAperti = {
  contenutiAperti: ArticlePreviewType[];
};

const ContenutiAperti: FC<ContenutiAperti> = ({ contenutiAperti }) => {
  return (
    <>
      {contenutiAperti.map((article) => (
        <div
          key={article.post_name}
          className="articleWrap border-box flex shrink grow basis-[20%] items-stretch print:px-0"
        >
          <article className={`flex w-full flex-col gap-3 sm:gap-5`}>
            <div className="entry-header flex flex-col">
              <header>
                {article?.meta != null && (
                  <CategorieList
                    categories={article?.meta}
                    className="entry-cat text-sm uppercase text-black hover:underline print:my-2"
                  />
                )}
                <h3 className="pt-2 text-2xl font-bold md:text-3xl xl:text-2xl 2xl:text-3xl">
                  <Link
                    className="entry-title text-black transition-all hover:text-orange"
                    href={`/a/${article.post_name}`}
                    dangerouslySetInnerHTML={{ __html: article.post_title }}
                  ></Link>
                </h3>
                <Badge meta={article?.meta} />
              </header>
              <div className="entry-summary mt-2 sm:text-lg print:my-2">
                <span
                  dangerouslySetInnerHTML={{
                    __html: article.post_excerpt,
                  }}
                />
              </div>
            </div>
            <ArrowRightLong
              href={`/a/${article.post_name}`}
              color={"black"}
              message={"Scopri"}
            />
          </article>
        </div>
      ))}
    </>
  );
};

export default ContenutiAperti;
