import Link from "next/link";
import { Fragment, type FC } from "react";
import { type ISSUES, type MetaObjectParsed } from "~/types";
import { returnUrlTypeCategory } from "~/utils/utils";

type CategoriesListProps = {
  categories: MetaObjectParsed;
  className?: string;
};

const CategorieList: FC<CategoriesListProps> = ({ categories, className }) => {
  let categoryHeading;
  if (
    categories?.cat_heading ||
    categories?.cat_speciali ||
    categories?.cat_multimedia
  ) {
    categoryHeading = [];
    if (categories?.cat_heading) categoryHeading.push(categories?.cat_heading);
    if (categories?.cat_speciali)
      categoryHeading.push(categories?.cat_speciali);
    if (categories?.cat_multimedia)
      categoryHeading.push(categories?.cat_multimedia);
  } else categoryHeading = Object.values(categories?.categories);

  const categoriesList = Boolean(categoryHeading) ? categoryHeading : null;

  return (
    <>
      {categoriesList?.map((category, index) => (
        <Fragment key={category.slug}>
          <Link
            className={`${className}`}
            href={
              category.slug != "speciali"
                ? returnUrlTypeCategory(
                    category.slug,
                    category.taxonomy as ISSUES
                  )
                : "/speciali"
            }
          >
            {category.name.replace("&amp;", " & ")}
          </Link>

          {index < categoriesList.length - 1 && (
            <span className="text-white">, </span>
          )}
        </Fragment>
      ))}
    </>
  );
};
export default CategorieList;
