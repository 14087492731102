import React, { type FC } from "react";
import { ISSUES_TYPE, type ArticlePreviewType } from "~/types";
import Link from "next/link";
import CategorieList from "../CategoriesList";

type NumeroCorrente = {
  numeroCorrente: ArticlePreviewType[];
};

const NumeroCorrente: FC<NumeroCorrente> = ({ numeroCorrente }) => {
  const firstArticoleSpeciale = numeroCorrente.findIndex((numeroCorremte) =>
    JSON.stringify(numeroCorremte?.meta?.categories).includes(
      ISSUES_TYPE.speciali
    )
  );
  const catSpeciale = numeroCorrente[firstArticoleSpeciale]
    ? Object.values(
        numeroCorrente[firstArticoleSpeciale]?.meta?.categories
      ).filter((cat) => cat.taxonomy == ISSUES_TYPE.speciali)
    : null;
  return (
    <div className="md:mt-4">
      {numeroCorrente?.map((article, index) =>
        !JSON.stringify(article?.meta?.categories)?.includes(
          ISSUES_TYPE.speciali
        ) ? (
          <div
            key={article.post_name}
            className="articleWrap border-box mt-12 flex shrink grow basis-[20%] items-stretch print:mt-4"
          >
            <article className={`flex w-full flex-row gap-5`}>
              <div className="entry-header flex flex-col">
                <header>
                  {article?.meta != null &&
                    (index == 0 ||
                      numeroCorrente[index]?.meta?.cat_heading == undefined ||
                      numeroCorrente[index]?.meta?.cat_heading?.slug !=
                        numeroCorrente[index - 1]?.meta?.cat_heading?.slug) && (
                      <CategorieList
                        categories={article?.meta}
                        className="entry-cat text-sm font-semibold uppercase tracking-[2px] text-[#90939a] hover:underline print:my-1"
                      />
                    )}
                  <h3 className="mt-1 text-2xl font-medium text-[#3b444d] hover:opacity-80 print:my-1 print:text-[20px]">
                    <Link
                      className="entry-title serif print:my-2"
                      href={`/a/${article.post_name}`}
                      dangerouslySetInnerHTML={{ __html: article.post_title }}
                    ></Link>
                  </h3>
                </header>
              </div>
            </article>
          </div>
        ) : (
          index == firstArticoleSpeciale && (
            <div
              key={article.post_name}
              className="articleWrap border-box mt-12 flex shrink grow basis-[20%] items-stretch print:mt-4"
            >
              <article className={`flex w-full flex-row gap-5`}>
                <div className="entry-header flex flex-col">
                  <header>
                    {article?.meta != null && (
                      <Link
                        href={`/speciali`}
                        className="entry-cat text-sm font-semibold uppercase tracking-[2px] text-[#90939a] hover:underline print:my-1"
                      >
                        Speciale
                      </Link>
                    )}
                    <h3 className="mt-1 text-[22px] font-medium text-[#3b444d] hover:opacity-80 print:my-1">
                      <Link
                        className="entry-title serif print:my-2"
                        href={`/s/${catSpeciale[0].slug}`}
                        dangerouslySetInnerHTML={{
                          __html: catSpeciale[0].name,
                        }}
                      ></Link>
                    </h3>
                  </header>
                </div>
              </article>
            </div>
          )
        )
      )}
    </div>
  );
};

export default NumeroCorrente;
