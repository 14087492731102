import Image from "next/image";
import { Link } from "react-alice-carousel";
import { returnUrlTypeCategory } from "~/utils/utils";

export type postNumber = {
  name: string;
  slug: string;
  taxonomy?: string;
};

const PreviewSpecialiCarouselLoop = ({ article }) => {
  const postExcerpt = article.description;

  return (
    <article className="flex grow flex-col">
      <figure>
        <Link
          href={`/s/${article.slug}`}
          className="flex w-full rounded-[50px] bg-lightLightGray transition-all"
        >
          {article.img != null && (
            <Image
              src={article.img}
              width={375}
              height={300}
              alt={
                article?.alt || "Copertina dello speciale di Buddismo e Società"
              }
              className="!aspect-5/3 h-full w-full rounded-[40px] object-cover mix-blend-multiply hover:opacity-80"
            />
          )}
        </Link>
      </figure>
      <div className="entry-header flex flex-col text-center">
        <header>
          <p className="pb-4 pt-6 md:pb-6 md:pt-12 print:my-2">
            {article?.numeroSlug != null && (
              <Link
                className="entry-title text-sm font-light uppercase transition-all hover:text-orange print:my-2"
                href={returnUrlTypeCategory(
                  article?.numeroSlug,
                  "issuem_issue"
                )}
              >
                BS {article.numeroName.replace("&amp;", " & ")}&nbsp; /&nbsp;
              </Link>
            )}
            <Link
              className="entry-title text-sm font-bold uppercase transition-all hover:text-orange print:my-2"
              href={`/s/${article.slug}`}
              dangerouslySetInnerHTML={{ __html: article.name }}
            ></Link>
          </p>
        </header>
        <div className="entry-summary text-sm print:my-2">
          <span
            dangerouslySetInnerHTML={{
              __html: postExcerpt,
            }}
          />
        </div>
      </div>
    </article>
  );
};

export default PreviewSpecialiCarouselLoop;
