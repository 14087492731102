export type MetaObjectParsed = {
  occhiello?: string;
  audio?: string;
  autore?: string;
  video?: string;
  sottotitolo?: string;
  _no_login_field?: string;
  footnotes?: string;
  cat_heading?: {
    name: string;
    slug: string;
    taxonomy?: string;
  };
  cat_multimedia?: {
    name: string;
    slug: string;
    taxonomy?: string;
  };
  cat_speciali?: {
    name: string;
    slug: string;
    taxonomy?: string;
  };

  number?: {
    [id in number]: {
      name: string;
      slug: string;
      taxonomy?: string;
    };
  };
  categories?: {
    [id in number]: {
      name: string;
      slug: string;
      taxonomy?: string;
    };
  };
};

export type CatHeadingObject = {
  categories?: {
    name: string;
    slug: string;
    taxonomy?: string;
  };
  speciali?: {
    name: string;
    slug: string;
    taxonomy?: string;
  };
  multimedia?: {
    name: string;
    slug: string;
    taxonomy?: string;
  };
};

export type ArticlePreviewType = {
  post_name: string;
  post_title: string;
  post_excerpt: string;
  meta: MetaObjectParsed;
  thumbnail: string;
  post_date: string;
  alt?: string;
  post_status?: string;
  post_content?: string;
};

export enum ISSUES_TYPE {
  categories = "issuem_issue_categories",
  multimedia = "issuem_issue_multimedia",
  numbers = "issuem_issue",
  speciali = "issuem_issue_speciali",
}

export type ISSUES =
  | "issuem_issue_categories"
  | "issuem_issue_multimedia"
  | "issuem_issue"
  | "issuem_issue_speciali";
