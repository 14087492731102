import React, { useEffect, useState, type FC } from "react";

type TitleLoopProps = {
  titleLoop: string;
  customClass: string;
};

const TitleLoop: FC<TitleLoopProps> = ({ titleLoop, customClass }) => {
  const [customClassComponent, setCustomClassComponent] = useState<string>("");

  useEffect(() => {
    setCustomClassComponent(customClass);
  }, [customClass]);
  return (
    <>
      <h2 className={customClassComponent}>{titleLoop}</h2>
    </>
  );
};

export default TitleLoop;
