import { useEffect, useState, type FC } from "react";
import { type ArticlePreviewType } from "~/types";
import Link from "next/link";
import Image from "next/image";
import { ArrowRight } from "../ArrowComponents";

type RubricaProps = {
  rubrica: ArticlePreviewType[];
  nameRubrica: string;
  slug: string;
  customClass: string;
};

const Rubriche: FC<RubricaProps> = ({ rubrica, slug, customClass }) => {
  const [customClassComponent, setCustomClassComponent] = useState<string>("");

  useEffect(() => {
    setCustomClassComponent(customClass);
  }, [customClass]);

  return (
    <div className={` ${customClassComponent}`}>
      <div className="flex flex-col gap-y-11">
        {rubrica.length != 0 &&
          rubrica.map((articolo, index, { length }) => (
            <div
              className={`${
                articolo.thumbnail != null
                  ? "flex flex-row gap-4 max-[420px]:flex-col sm:gap-16 md:gap-8 lg:gap-16"
                  : ""
              }`}
              key={index}
            >
              {articolo.thumbnail != null && (
                <div className="max-[420px]:mb-4 md:mb-0">
                  <Image
                    src={articolo.thumbnail}
                    width={400}
                    height={200}
                    alt={articolo?.alt || articolo.post_title}
                    className="h-auto min-w-[140px] max-w-[140px]"
                  />
                </div>
              )}
              <div className="flex flex-col gap-y-5">
                <Link
                  className="text-xl font-bold text-black transition-all hover:text-orange max-sm:leading-[26px] sm:text-2xl"
                  href={`/a/${articolo.post_name}`}
                  dangerouslySetInnerHTML={{ __html: articolo.post_title }}
                ></Link>
                <div className="">
                  <span
                    className="font-normal leading-6 text-gray max-sm:text-base sm:text-sm"
                    dangerouslySetInnerHTML={{
                      __html: articolo.post_excerpt,
                    }}
                  />
                </div>
                {length - 1 === index && (
                  <ArrowRight
                    href={slug}
                    color={"black"}
                    message={"Vedi tutto"}
                    divStyles={"mt-12"}
                  />
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Rubriche;
