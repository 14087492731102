import React, { type FC } from "react";
import { type ArticlePreviewType } from "~/types";
import { RESPONSIVE_CAROUSEL } from "~/utils/constants";
import AliceCarousel from "react-alice-carousel";
import NextButton from "./NextButton";
import PrevButton from "./PrevButton";
import PreviewCarouselLoop from "./ArticlePreview/PreviewCarouselLoop";

type CarouselLoopProps = {
  carouselLoop: ArticlePreviewType[];
};

const CarouselLoop: FC<CarouselLoopProps> = ({ carouselLoop }) => {
  return (
    <AliceCarousel
      mouseTracking={true}
      responsive={RESPONSIVE_CAROUSEL}
      autoPlay={true}
      autoPlayInterval={4000}
      disableDotsControls={true}
      disableButtonsControls={false}
      renderPrevButton={() => {
        return <PrevButton color={"black"} />;
      }}
      renderNextButton={() => {
        return <NextButton color={"black"} />;
      }}
    >
      {carouselLoop.map((article) => (
        <div
          key={`${article.post_name}`}
          className="articleWrap carousel-cell h-100 aspect-5/3 flex w-full flex-col px-2 md:px-2"
        >
          <PreviewCarouselLoop article={article} />
        </div>
      ))}
    </AliceCarousel>
  );
};

export default CarouselLoop;
