const NextButton = ({ color }) => {
  return (
    <button
      className={`bg-${color} alice-carousel-button alice-carousel-prev-next-button next !h-[40px] !w-[40px] !translate-y-[calc(-50%-10px)] focus:!shadow-[0_0_0_2px_orange]`}
      type="button"
      aria-label="Prossimo"
    >
      <svg className="alice-carousel-button-icon" viewBox="0 0 100 100">
        <title>Next</title>
        <path
          d="M 10, 50 L 60, 100 L 70, 90 L 30, 50 L 70, 10 L 60, 0 Z"
          className="arrow"
          transform="translate(100, 100) rotate(180)"
        ></path>
      </svg>
    </button>
  );
};

export default NextButton;
