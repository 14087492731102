import { type FC } from "react";
import { type MetaObjectParsed } from "~/types";
type BadgeProps = {
  meta: MetaObjectParsed;
  style?: string;
};

const AudioBadge = ({ style }) => {
  return (
    <span
      className={`${style} my-2 mr-2 inline-block rounded-lg bg-gray px-2 py-1 text-xs text-white print:hidden`}
    >
      AUDIO
    </span>
  );
};

const VideoBadge = ({ style }) => {
  return (
    <span
      className={`my-2 mr-2 inline-block rounded-lg bg-gray px-2 py-1 text-xs text-white print:hidden`}
    >
      VIDEO
    </span>
  );
};

const FreeBadge = ({ style }) => {
  return (
    <span
      className={`my-2 mr-2 inline-block rounded-lg bg-orange px-2 py-1 text-xs text-white print:hidden`}
    >
      FREE
    </span>
  );
};

const Badge: FC<BadgeProps> = ({ meta, style }) => {
  const isFree = meta?._no_login_field;
  const isAudio = !!meta?.audio;
  const isVideo = !!meta?.video;

  return (
    <>
      {(isAudio || isVideo || isFree) && (
        <div>
          {isAudio && <AudioBadge style={style} />}
          {isVideo && <VideoBadge style={style} />}
          {isFree && <FreeBadge style={style} />}
        </div>
      )}
    </>
  );
};

export default Badge;
