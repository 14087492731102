import classNames from "classnames";
import React from "react";

type LoadingType = {
  className?: string;
};

const Loading: React.FC<LoadingType> = ({ className }) => (
  <svg
    className={classNames(
      "-ml-1 mr-3 h-5 w-5 animate-spin text-white",
      className
    )}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);
export default Loading;

export const LoadingCarousel: React.FC<LoadingType> = () => (
  <div className="grid animate-pulse gap-4 px-4 md:grid-cols-2">
    <div className="aspect-5/3 w-full rounded-[75px] bg-slate-200"></div>
    <div className="aspect-5/3 hidden w-full rounded-[75px] bg-slate-200 sm:flex"></div>
  </div>
);
