import React, { type FC } from "react";
import { type ArticlePreviewType } from "~/types";
import AliceCarousel, { Link } from "react-alice-carousel";
import NextButton from "../NextButton";
import PrevButton from "../PrevButton";
import Badge from "../ArticlePreview/Badge";

type EvidenzaLoopProps = {
  evidenzalLoop: ArticlePreviewType[];
};

const EvidenzaLoop: FC<EvidenzaLoopProps> = ({ evidenzalLoop }) => {
  return (
    <AliceCarousel
      mouseTracking={true}
      responsive={{
        0: { items: 1.1 },
        640: { items: 1.2 },
        820: { items: 1.7 },
        1024: { items: 2.1 },
        1200: { items: 2.3 },
        1500: { items: 2.5 },
        1700: { items: 2.7 },
      }}
      autoPlay={true}
      autoPlayInterval={4000}
      disableDotsControls={true}
      disableButtonsControls={false}
      renderPrevButton={() => {
        return <PrevButton color={"black"} />;
      }}
      renderNextButton={() => {
        return <NextButton color={"black"} />;
      }}
    >
      {evidenzalLoop.map((article) => (
        <div
          key={`${article.post_name}`}
          className="articleWrap carousel-cell flex h-full w-full px-2 sm:aspect-[5/3]"
        >
          {article.thumbnail ? (
            <article
              className="flex grow flex-col justify-end gap-4 rounded-[60px] bg-gradient-to-r from-black to-black bg-cover px-10 py-8 lg:gap-y-2 lg:px-10 lg:py-6 xl:rounded-[70px] xl:p-10 2xl:p-14"
              style={{
                backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 0.9) 100%), url(${article.thumbnail})`,
              }}
            >
              <div className="entry-header flex flex-col gap-4 lg:gap-6">
                <header>
                  <h3 className="text-[30px] font-bold text-white xl:text-[36px] 2xl:text-[42px]">
                    <Link
                      className="entry-title serif !leading-[95%] print:my-2"
                      href={`/a/${article.post_name}`}
                      dangerouslySetInnerHTML={{ __html: article.post_title }}
                    ></Link>
                  </h3>
                  {article?.meta?.occhiello && (
                    <h4 className="mt-4 text-base !leading-relaxed text-white">
                      {article?.meta?.occhiello}
                    </h4>
                  )}
                  <Badge meta={article?.meta} />
                </header>
              </div>
            </article>
          ) : (
            <article className="border-tr flex grow flex-col justify-end gap-4 rounded-[75px] bg-green px-10 py-8 lg:gap-y-2 lg:px-10 lg:py-6 xl:rounded-[90px] xl:p-10 2xl:p-14">
              <div className="entry-header flex flex-col gap-4 lg:gap-6">
                <header>
                  <h3 className="pt-4 text-[40px] font-bold !leading-[105%] text-white xl:text-[46px] 2xl:text-[52px]">
                    <Link
                      className="entry-title !leading-none print:my-2"
                      href={`/a/${article.post_name}`}
                      dangerouslySetInnerHTML={{ __html: article.post_title }}
                    ></Link>
                  </h3>
                  <Badge meta={article?.meta} />
                </header>
                <div className="entry-summary text-[20px] font-light !leading-none text-white xl:text-[20px] 2xl:text-[24px] print:my-2">
                  {article?.meta?.occhiello && (
                    <span>{article?.meta?.occhiello}</span>
                  )}
                </div>
              </div>
            </article>
          )}
        </div>
      ))}
    </AliceCarousel>
  );
};

export default EvidenzaLoop;
